#landing-page .landing-content{
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    margin: 1rem 2rem 5px;

    padding: 20px;
}
#landing-page .landing-content .link{
    text-decoration: none;
    color: #FFF;
    
}

#landing-page .landing-content .container{
    text-decoration: none;
    color: white;
    
    width: 14rem;
    height: 7rem;
    padding: 10px;

    border-radius: 20px;
    margin: 1rem auto 0.5rem;

    display: flex;
    flex-direction: column;

    transition: all 0.2s;
    background: var(--color-black-purple-secondary);
}

#landing-page .landing-content .container:hover{
    color: var(--color-secondary);
    border: solid 3px #FFF;
}

#landing-page .landing-content .container h2{
    display: flex;
    justify-content: center;

    font-size: 1.4rem;
    margin-bottom: 10px;
}

#landing-page .landing-content .container .logo{
    display: flex;
    align-self: center;

    margin: auto;
}


@media (max-width: 1020px) {
    
    #landing-page .landing-content{
        display: flex;
        flex-direction: column;
        padding: 0;

        margin: 0rem;
    }
    #landing-page .landing-content .container{
        width: 15rem;
        height: 5rem;
        padding: 4px;
    }


    #landing-page .landing-content .container h2{
        font-size: 1rem;
        margin-bottom: 4px;
    }

}
@media (min-width: 1360px) {
    #landing-page .landing-content{
        margin: 1rem 2rem 0;
    }

    #landing-page .landing-content .container{
        width: 18rem;
        height: 9rem;
        padding: 14px;
    }

    #landing-page .landing-content .container h2{
        font-size: 1.8rem;
    }
}
@media (min-width: 2560px) {
    #landing-page .landing-content{
        margin-top: 2rem;
    
        padding: 20px 40px;
    }
    
    #landing-page .landing-content .container{
        text-decoration: none;
    
        width: 25rem;
        height: 10rem;
        padding: 14px;
    }

    #landing-page .landing-content .container h2{
        font-size: 2.1rem;
    }
}