:root {
    --color-background: #121214;
    --color-green: #04d361;
    --color-red: #e83f5b;
    --color-orange: #fd951f;
    --color-yellow: #f7df1e;
    --color-primary: #8257e6;
    --color-primary-hover: #9466ff;
    --color-secondary: #e1e1e6;
    --color-text: #a8a8b3;
    --color-support: #737380;
    --color-shape: #202024;
    --color-shape-hover: #29292e;
    --color-icons: #41414d;
    --color-borders: #323238;
    --color-black: #0d0d0f;
    --color-black-purple: #140722;
    --color-black-purple-secondary: #1c0135;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

body{
    color: #FFF;
    background: var(--color-black-purple);
}

body, input, button, textarea{
    font: 400 18px Raleway, sans-serif;
}
