#header .primary-area .main-header{
    padding: 10px;

    display: grid;
    grid-row: 1fr;
}

#header .primary-area .main-header .profile-photo{
    display: flex;
    justify-content: center;
}
#header .primary-area .main-header .profile-photo img{
    width: 9.5rem;
    height: 9.5rem;
    margin: 2rem 0 0;

    border-radius: 100%;

    transition: all 0.2s;
}

#header .primary-area .main-header .profile-photo img:hover{
    border: solid 2px var(--color-secondary);
}

#header .primary-area .landing-text{
    text-align: center;
    font-size: 1rem;
    padding: 0 50px;
    margin: 0;
}

#header .primary-area .landing-text h1{
    font-size: 1.2rem;
    font-weight: 500;
    margin: 10px 0;
}
#header .primary-area .landing-text p{
    font-size: 0.8rem;
}

@media(min-width: 768px){
    #header .primary-area .main-header .profile-photo img{
        width: 11rem;
        height: 11rem;
        margin-bottom: 0rem;
    }
    #header .primary-area .landing-text h1{
        font-size: 1.55rem;
    }

}
@media(min-width: 1024px){
    #header .primary-area .main-header .profile-photo img{
        width: 15rem;
        height: 15rem;
        margin-bottom: 0rem;
    }
    #header .primary-area .landing-text h1{
        font-size: 1.9rem;
    }
    #header .primary-area .landing-text p{
        font-size: 1.3rem;
    }
}

@media (min-width: 2560px){
    #header .primary-area .main-header .profile-photo img{
        width: 15rem;
        height: 15rem;
        margin-bottom: 0rem;
    }
    #header .primary-area .landing-text h1{
        font-size: 2.5rem;
    }
    #header .primary-area .landing-text p{
        font-size: 1.5rem;
    }

}